


































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import PersonService from "@/services/PersonService";
import {STATE_LIST} from "@/constants/ProfileConstants";
import Workspaces from "@/state/Workspaces";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import {processError} from "@/utils/ComponentUtils";
import EADList from "@/components/profile/person/EADList.vue";
import SSNCard from "@/components/profile/person/SSNCard.vue";
import StateIdList from "@/components/profile/person/StateIdList.vue";
import GreenCardList from "@/components/profile/person/GreenCardList.vue";
import CitizenPassportList from "@/components/profile/person/CitizenPassportList.vue";
import {ResidencyStatus} from "@/constants/PersonConstants";
import ForeignIDList from "@/components/profile/person/ForeignIDList.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  computed: {
    states(): Array<string> {
      return STATE_LIST;
    },
    ResidencyStatus() {
      return ResidencyStatus
    },
    AutoCompleteDTO() {
      return AutoCompleteDTO
    }
  },
  components: {
    ForeignIDList,
    PortalInput, AutoComplete, EADList, SSNList: SSNCard, StateIdList, GreenCardList, CitizenPassportList}
})
export default class PersonDocuments extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Auth.Getter("hasAdministrativePrivileges")
  private hasAdministrativePrivileges!: boolean;

  tabIndex = 0;

  private message = "";

  private successful = false;

  residencyStatus: ResidencyStatus | null = null;

  mounted() {
    this.loadPerson();
    this.tabIndex = Number(this.$route.params.tabIndex);
  }

  get currentTabIndex() {
    return parseInt(this.$route.params.tabIndex, 10) || 0;
  }

  loadPerson() {
    this.startLoading()
    PersonService.getById(Workspaces.getCurrent.id).then(
        ok => {
          this.residencyStatus = ok.data.residencyStatus
          this.stopLoading()
        },
        err => processError(err, this)
    )
  }

  changeTab(index: number){
    let params = this.$route.params
    params.tabIndex = index.toString();
    this.$router.replace({params: params});
    this.tabIndex = index;
    console.log(this.tabIndex)
  }

  onChangeResidencyStatus() {
    this.startLoading()
    if (this.residencyStatus != null) {
      PersonService.changeResidencyStatus(Workspaces.getCurrent.id, this.residencyStatus).then(
          ok => {
            this.stopLoading()
          },
          err => processError(err, this)
      )
    }
  }
}
